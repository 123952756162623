export default [
  {
    component: 'CNavTitle',
    name: 'Yonetim',
  },
  {
    component: 'CNavItem',
    name: 'Fuar  Listesi',
    to: '/fair/list',
  },
  {
    component: 'CNavItem',
    name: 'Güncel Fuara katılımcı ekle',
    to: '/admin/register-participant',
  },
]
