<template>
  <CFooter class="justify-content-center">
    <div>
      <a href="https://comitfy.com" target="_blank" class="nav-link d-inline"
        >Comitfy</a
      >
      <span class="ms-1">&copy; 2022</span>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'AppFooter',
}
</script>
